import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["remove","fileField","image","deleteLogoField"]

  remove(event) {
    event.preventDefault()
    this.fileFieldTarget.style.display = 'block'
    this.fileFieldTarget.value = ''
    this.removeTarget.style.display = 'none'
    this.imageTarget.style.display = 'none'
    this.deleteLogoFieldTarget.value = 'true'
  }
}
