import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["submit", "everyXWeeks", "daysOfWeek", "scheduleType", "weeklyFields", "monthlyFields", "dayOfMonth", "months"]

  connect() {
    this.handleScheduleTypeChange()
  }

  selectAllDays() {
    event.preventDefault()
    for (var i=0, item; item = this.daysOfWeekTargets[i]; i++) {
      item.checked = true
    }
  }

  selectNoDays() {
    event.preventDefault()
    for (var i=0, item; item = this.daysOfWeekTargets[i]; i++) {
      item.checked = false
    }
  }

  selectAllMonths() {
    event.preventDefault()
    for (var i=0, item; item = this.monthsTargets[i]; i++) {
      item.checked = true
    }
  }

  selectNoMonths() {
    event.preventDefault()
    for (var i=0, item; item = this.monthsTargets[i]; i++) {
      item.checked = false
    }
  }

  handleScheduleTypeChange() {
    if (this.getScheduleTypeValue() == "weekly") {
      this.weeklyFieldsTarget.style.display = "block"
      this.monthlyFieldsTarget.style.display = "none"
    } else {
      this.monthlyFieldsTarget.style.display = "block"
      this.weeklyFieldsTarget.style.display = "none"
    }
  }
  
  handleSubmit(event) {
    if (this.getScheduleTypeValue() == "weekly") {
      let selectedDayCount = 0
      for (var i=0, item; item = this.daysOfWeekTargets[i]; i++) {
        if (item.checked === true) {
          selectedDayCount = selectedDayCount + 1
        }
      }

      if (selectedDayCount == 0) {
        alert("Please select at least one day.")
        event.preventDefault()
        return
      }
      
      if (this.everyXWeeksTarget.value > 1 && selectedDayCount != 1) {
        alert("Only tasks that occur every week can have multiple days. Please select only one day.")
        event.preventDefault()
      }
    }

    if (this.getScheduleTypeValue() == "monthly") {
      let selectedMonthCount = 0
      for (var i=0, item; item = this.monthsTargets[i]; i++) {
        if (item.checked === true) {
          selectedMonthCount = selectedMonthCount + 1
        }
      }
      
      if (selectedMonthCount == 0) {
        alert("Please select at least one month.")
        event.preventDefault()
        return
      }
    }
  }

  getScheduleTypeValue() {
    for(var i = 0; i < this.scheduleTypeTargets.length; i++) { 
      if(this.scheduleTypeTargets[i].checked) {
        return this.scheduleTypeTargets[i].value;
      }
    }
  }
}
