import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["progress"]

  connect() {
    addEventListener("direct-uploads:start", event => {
      this.progressTarget.style.display = "block"
    })
    addEventListener("direct-uploads:end", event => {
      this.progressTarget.style.display = "none"
    })
  }
}
