import { Controller } from 'stimulus'
import jstimezonedetect from 'jstimezonedetect'

export default class extends Controller {
  static targets = [ 'timezoneField' ]
  
  connect() {
    const tz = jstimezonedetect.determine()
    this.timezoneFieldTarget.value = tz.name()
  }
}
