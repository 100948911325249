import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["sendConfirmationEmail", "settings", "confirmationEmailBody", "discussionOption"]

  connect() {
     this.toggleChoices();
  }

  toggleChoices() {
    const discussionOptionValue = this.getDiscussionOptionValue()
    if (discussionOptionValue == "true") {
      this.settingsTarget.style.display = "block"
    } else {
      this.settingsTarget.style.display = "none"
    }

    if (this.sendConfirmationEmailTarget.checked) {
      this.confirmationEmailBodyTarget.style.display = "block"
    } else {
      this.confirmationEmailBodyTarget.style.display = "none"
    }

  }

  getDiscussionOptionValue() {
    for(var i = 0; i < this.discussionOptionTargets.length; i++) { 
      if(this.discussionOptionTargets[i].checked) {
        return this.discussionOptionTargets[i].value;
      }
    }
  }
}
