import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['discussionOptions', 'discussionUnavailable', 'targetTypeTodoList', 'targetTypeCardTableColumn', 'discussionDisabled']
  
  connect() {
    this.toggleDiscussion();
  }

  toggleDiscussion() {
    if (this.targetTypeTodoListTarget.checked) {
      this.discussionOptionsTarget.style.display = 'block';
      this.discussionUnavailableTarget.style.display = 'none';
    } else {
      this.discussionUnavailableTarget.style.display = 'block';
      this.discussionOptionsTarget.style.display = 'none';
      this.discussionDisabledTarget.click();
    }
  }
}
