import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["fieldType", "choices", "required"]

  connect() {
     this.toggleChoices();
  }

  toggleChoices() {
    const fieldType = this.fieldTypeTarget.value;
    if (fieldType == "multiple_choice" || fieldType == "checkboxes") {
      this.choicesTarget.style.display = "block"
    } else {
      this.choicesTarget.style.display = "none"
    }

    if (fieldType == "checkboxes") {
      this.requiredTarget.style.display = "none"
    } else {
      this.requiredTarget.style.display = "block"
    }
  }
}
