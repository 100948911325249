import { Controller } from 'stimulus'
import consumer from "channels/consumer";

export default class extends Controller {
  static targets = ["status"]

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "FormSubmissionChannel",
        id: this.data.get("id"),
      },
      {
        received: this._received.bind(this),
      }
    );
   }

  disconnect() {
    this.subscription.unsubscribe();
  }

  _received(data) {
    if (data && data.length > 0) {
      this.statusTarget.innerHTML = `<p><a href=\"${data}\">View the submission in Basecamp</p>`;
    }
  }
}
